import BaseModel from './base'
import type { _BaseType } from './_base_type'
import type { CarrierType } from './carrier'
import type { DeliveryZoneType } from './deliveryZone'
import type { ObjectId } from 'bson'

export type ProductShippingMarkupType = _BaseType & {
  productId: ObjectId
  carrierId: ObjectId
  zoneId: ObjectId
  amount: number

  carrier?: CarrierType
  zone?: DeliveryZoneType
}

export class ProductShippingMarkupModel extends BaseModel {
  constructor() {
    super('productShippingMarkups')
    this.uniques = [['productId', 'carrierId', 'zoneId']]
    this.required = ['productId', 'carrierId', 'zoneId']

    this.fields = {
      amount: {
        type: 'money',
      },
      productId: {
        type: 'objectId',
      },
      carrierId: {
        type: 'objectId',
      },
      zoneId: {
        type: 'objectId',
      },
    }
  }
}
