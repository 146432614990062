import BaseModel, { ModelRelationType } from './base'
import { OfferModel, type OfferType } from './offer'
import { ProductModel, type ProductType } from './product'
import type { _BaseType } from './_base_type'

export type OfferProductsType = _BaseType & {
  productId: string
  offerId: string
  enable: boolean

  product?: ProductType
  offer?: OfferType
}

export class OfferProductsModel extends BaseModel {
  constructor() {
    super('offerProducts')

    this.uniques = [
      ['productId', 'offerId']
    ]

    this.required = ['productId', 'offerId']

    this.fields = {
      offerId: { type: 'string' },
      productId: { type: 'string' },
      enable: { type: 'boolean' },
    }
  }

  setRelation() {
    this._relation!.product = {
      type: ModelRelationType.BelongsTo,
      model: new ProductModel(),
      foreignKey: '_id',
      targetKey: 'productId',
    }

    this._relation!.offer = {
      type: ModelRelationType.BelongsTo,
      model: new OfferModel(),
      foreignKey: '_id',
      targetKey: 'offerId',
    }
  }
}
