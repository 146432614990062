import { ObjectId } from 'bson'
import type { _BaseType } from './_base_type'
import type { PhotoURL } from './photo_url'
import BaseModel, { ModelRelationType } from './base'
import type { VariantGroupType } from './variantGroup'
import { VariantGroupModel } from './variantGroup'
import type { RatableType } from './ratable'
import type { OfferProductsType } from './offer_products'
import { OfferProductsModel } from './offer_products'
import type { ProductRatingType } from './product_rating'
// import { CategoryModel } from './category'

// if product has variant, use variants.quantity
export interface ProductBundleType {
  productId: ObjectId
  variants: {
    variantId: ObjectId
    itemId: ObjectId
    quantity: number
  }[]
  quantity?: number
}

export interface ProductMediumType {
  name: string
  path: string
  mimeType: string
  urls?: PhotoURL
}

export type ProductType = RatableType &
_BaseType & {
  name: string
  sku: string

  isBundle?: boolean
  hasVariant?: boolean

  sourceChannelId?: ObjectId // stock source from

  medium: ProductMediumType[]
  photo?: string
  description?: string
  longDescription?: string
  ingredients: string

  finePrint?: string

  unitPrice: number
  normalUnitPrice?: number
  variantGroup?: ObjectId

  variantGroupRecord?: VariantGroupType
  tags: string[]

  categories: ObjectId[]
  photoURL?: PhotoURL

  weightG: number

  stockControl: boolean
  stockQuantity: number
  // sorting weight
  weight?: number

  bundleItems?: ProductBundleType[]
  status: number

  syncStockFrom?: ObjectId[]

  offerProducts?: OfferProductsType[]

  rating?: ProductRatingType[]
  totalRate?: number
  totalUser?: number
}
export class ProductModel extends BaseModel {
  constructor() {
    super('products')
    this.uniques = [['sku']]
    this.required = ['name', 'sku', 'weightG', 'stockQuantity']

    this.fields = {
      weightG: { label: 'Weight', type: 'number' },
    }
  }

  setRelation() {
    // TODO: add relation many to many?
    // this._relation!.categoriesRecord = {
    //   model: new CategoryModel(),
    //   foreignKey: 'categories',
    //   targetKey: '_id',
    //   type: ModelRelationType.BelongsTo,
    // }

    this._relation!.variantGroupRecord = {
      model: new VariantGroupModel(),
      foreignKey: 'variantGroup',
      targetKey: '_id',
      type: ModelRelationType.BelongsTo,
    }

    this._relation!.offerProduct = {
      type: ModelRelationType.HasMany,
      model: new OfferProductsModel(),
      foreignKey: 'productId',
      targetKey: '_id',
    }
  }
}
