import type { PhotoURL } from './photo_url'
import BaseModel from './base'
import type { _BaseType } from './_base_type'

export type VariantGroupType = _BaseType & {
  name: string
  variants: string[]
  status: number
}
export class VariantGroupModel extends BaseModel {
  constructor() {
    super('variantGroups')
    this.uniques = [
      ['name']
    ]
    this.required = ['name']
  }
}
